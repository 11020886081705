
import SignUpFormReact from "./SignUpForm";

function App() {

  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen">
      <nav className="py-2 px-6 md:px-3 fixed top-0 left-0 w-screen">
        <div className=" max-w-5xl w-full  mx-auto">
          <img src={"https://res.cloudinary.com/dho5gjrlr/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1726753028/Frame_22_oniibw.png"} alt="" className="w-auto h-20 object-contain" />
        </div>

      </nav>
      <h1 className="text-6xl md:text-9xl md:leading-loose pb-8 font-bold bg-gradient-to-r from-[#4285f4] via-[#d96570] to-[#9b72cb] inline-block text-transparent bg-clip-text">
        Coming Soon
      </h1>
      <p className="text-xl  md:text-2xl text-center text-slate-900">Shop Smart, Spend Slick - Your Gateway to Savvy Savings!</p>
      <div className="my-5" />
      <SignUpFormReact />

    </div>
  );
}

export default App;
